import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TextInput from './primitive/TextInput';
import TextArea from './primitive/TextArea';
import Dropdown from './primitive/DropDownInput';
import CommonHeader from "../common/CommonHeader";
import config from "../../config";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CommonNavigation from "../common/CommonNavigation";
import {Notification} from '../../interface/notificationInfo'; // импорт интерфейсов

const NotificationCreate: React.FC = () => {
    const [formData, setFormData] = useState<Partial<Notification>>({});
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploading, setUploading] = useState<boolean>(false);
    const navigate = useNavigate();
    const formRef = useRef<HTMLDivElement>(null);

    const handleChange = (name: string, value: any) => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        const errors: string[] = [];
        if (!formData.name) errors.push("Введите название уведомления");
        if (!formData.text) errors.push("Введите текст уведомления");

        if (errors.length > 0) {
            setFormErrors(errors);
            formRef.current?.scrollIntoView({ behavior: 'smooth' });
            return;
        }

        try {
            setUploading(true);

            const notificationData = {
                ...formData,
            };

            const createResponse = await fetch(config.apiBaseUrl + '/api/notifications', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(notificationData)
            });

            if (!createResponse.ok) {
                throw new Error('Не удалось создать уведомление');
            }

            const result = await createResponse.json();
            navigate(-1);
        } catch (error) {
            console.error('Ошибка при создании уведомления:', error);
        } finally {
            setUploading(false);
            setUploadProgress(0);
        }
    };

    return (
        <>
            <CommonNavigation isBack/>
            <div className="custom-form">
                <CommonHeader name="Создать уведомление"/>
                <div className="form-container" ref={formRef}>
                    {formErrors.length > 0 && (
                        <div className="error-messages">
                            {formErrors.map((error, index) => (
                                <p key={index} className="error-message">{error}</p>
                            ))}
                        </div>
                    )}
                    <TextInput
                        label="Название уведомления"
                        placeholder="Введите название"
                        name="name"
                        value={formData.name || ''}
                        onChange={handleChange}
                    />
                    <TextArea
                        label="Текст уведомления"
                        placeholder="Введите текст уведомления"
                        name="text"
                        value={formData.text || ''}
                        onChange={handleChange}
                    />
                    <TextArea
                        label="Срок выполнения"
                        placeholder="Введите пояснения к сроку выполнения"
                        name="period"
                        value={formData.period || ''}
                        onChange={handleChange}
                    />

                    {uploading && (
                        <div className="progress-bar">
                            <CircularProgressbar value={uploadProgress} text={`${Math.round(uploadProgress)}%`}/>
                        </div>
                    )}
                    {!uploading && (
                        <button onClick={handleSubmit} disabled={uploading}>
                            Отправить
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default NotificationCreate;
