import config from "../../config";
// @ts-ignore
import { Notification } from "../../interface/notificationInfo";
import { InfoEditor } from "../../interface/infoEditor";

class NotificationEditor implements InfoEditor {
    private notifications: Notification[] = [];
    private filterChoices: string[] = [];

    constructor() {
        this.notifications = [];
    }

    async getAllInfo(start: number, end: number, observer: (info: any) => void): Promise<void> {
        try {
            const url = `${config.apiBaseUrl}/api/notifications?start=${start}&end=${end}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch notifications');
            }

            const notifications = await response.json();
            observer(notifications);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    }

    getFilterInfo(start: number, end: number, id: number, observer: (info: any) => void): void {
        return
    }

    async getSearchInfo(name: string, observer: (info: any) => void): Promise<void> {
        try {
            const url = `${config.apiBaseUrl}/api/notification/search?name=${name}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Search request failed');
            }

            const searchResults = await response.json();
            observer(searchResults);
        } catch (error) {
            console.error('Error searching notifications:', error);
        }
    }

    getContextInfo(): Array<Map<string, any>> {
        return this.notifications.map(notification => {
            const map = new Map<string, any>();
            for (const key in notification) {
                if (notification.hasOwnProperty(key)) {
                    map.set(key, notification[key as keyof Notification]);
                }
            }
            return map;
        });
    }

    getFilterChoices(): string[] {
        return this.filterChoices;
    }

    getEditForm(): string {
        return "/forms/edit-notification";
    }

    async deleteVar(id: number, observer: (info: any) => void): Promise<void> {
        try {
            const url = `${config.apiBaseUrl}/api/notifications/${id}`;
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to delete notification');
            }

            observer(await response.json());
        } catch (error) {
            console.error('Error deleting notification:', error);
        }
    }

    initChoices(): void {
        this.filterChoices = []; // Замените на нужные фильтры
    }

    setChoices(choices: string[]): void {
        this.filterChoices = choices;
    }

    getAddForm(): string {
        return "/forms/create-notification";
    }

    getTranslationsInfo(): { [key: string]: string } {
        return {
            text: "Описание",
            period: "Срок",
        };
    }
}

export default NotificationEditor;
