import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TextInput from './primitive/TextInput';
import TextArea from './primitive/TextArea';
import CommonHeader from "../common/CommonHeader";
import config from "../../config";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CommonNavigation from "../common/CommonNavigation";
import { Notification } from '../../interface/notificationInfo'; // импорт интерфейсов

const NotificationEdit: React.FC = () => {
    const [formData, setFormData] = useState<Partial<Notification>>({});
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploading, setUploading] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const formRef = useRef<HTMLDivElement>(null);
    const query = new URLSearchParams(location.search);
    const id = query.get("id");

    useEffect(() => {
        const fetchNotificationData = async () => {
            try {
                const response = await fetch(`${config.apiBaseUrl}/api/notifications/${id}`, {
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error('Не удалось получить данные уведомления');
                }
                const data = await response.json();
                setFormData(data);
            } catch (error) {
                console.error('Ошибка при загрузке уведомления:', error);
            }
        };
        if (id) {
            fetchNotificationData();
        }
    }, [id]);

    const handleChange = (name: string, value: any) => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const validateForm = () => {
        const errors: string[] = [];
        if (!formData.name) errors.push("Введите название уведомления");
        if (!formData.text) errors.push("Введите текст уведомления");
        setFormErrors(errors);
        return errors.length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            formRef.current?.scrollIntoView({ behavior: 'smooth' });
            return;
        }

        try {
            setUploading(true);
            setUploadProgress(0);

            const updatedNotificationData = {
                ...formData,
            };

            const updateResponse = await fetch(`${config.apiBaseUrl}/api/notifications/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(updatedNotificationData)
            });

            if (!updateResponse.ok) {
                throw new Error('Не удалось обновить уведомление');
            }

            const result = await updateResponse.json();
            navigate(-1);
        } catch (error) {
            console.error('Ошибка при обновлении уведомления:', error);
        } finally {
            setUploading(false);
            setUploadProgress(0);
        }
    };

    return (
        <>
            <CommonNavigation isBack />
            <div className="custom-form">
                <CommonHeader name="Редактировать уведомление" />
                <div className="form-container" ref={formRef}>
                    {formErrors.length > 0 && (
                        <div className="error-messages">
                            {formErrors.map((error, index) => (
                                <p key={index} className="error-message">{error}</p>
                            ))}
                        </div>
                    )}
                    <TextInput
                        label="Название уведомления"
                        placeholder="Введите название"
                        name="name"
                        value={formData.name || ''}
                        onChange={handleChange}
                    />
                    <TextArea
                        label="Текст уведомления"
                        placeholder="Введите текст уведомления"
                        name="text"
                        value={formData.text || ''}
                        onChange={handleChange}
                    />
                    <TextArea
                        label="Срок выполнения"
                        placeholder="Введите пояснения к сроку выполнения"
                        name="period"
                        value={formData.period || ''}
                        onChange={handleChange}
                    />

                    {uploading && (
                        <div className="progress-bar">
                            <CircularProgressbar value={uploadProgress} text={`${Math.round(uploadProgress)}%`} />
                        </div>
                    )}
                    {!uploading && (
                        <button onClick={handleSubmit} disabled={uploading}>
                            Сохранить
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default NotificationEdit;
