import React, { useEffect, useState } from "react";
import "../../css/library/library-main.css";
import CommonNavigation from "../common/CommonNavigation";
import LazyList from "../common/LazyList";
import LibraryViewModel from "../../viewmodel/LibraryViewModel";
import CommonHeader from "../common/CommonHeader";
import CommonSearchPanel from "../common/CommonSearchPanel";
import Filters from "../common/CommonFilterContainer";
import ListItem from "./ListItem";
import { useNavigate, useLocation } from "react-router-dom";
import { Item } from "../../interface/library";
import NotifyViewModel from "../../viewmodel/NotifyViewModel";

function NotifyMain() {
    const [activeCategory, setActiveCategory] = useState<string | null>(null);
    const [searchText, setSearchText] = useState<string>("");
    const [libraryInfo, setLibraryInfo] = useState<Item[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingText, setLoadingText] = useState<string>("Запрос к серверу");
    const viewModel = NotifyViewModel.getInstance();
    const navigate = useNavigate();
    const location = useLocation();
    // Анимация точек
    useEffect(() => {
        let dotIndex = 0;
        const dots = ['.', '..', '...'];
        const interval = setInterval(() => {
            setLoadingText(`Запрос к серверу${dots[dotIndex]}`);
            dotIndex = (dotIndex + 1) % dots.length;
        }, 500);

        return () => clearInterval(interval);
    }, []);

    // Проверка авторизации при загрузке компонента
    useEffect(() => {
        if (!viewModel.handleCheckAuth()) {
            navigate(`/`);
        }
    }, [navigate]);

    // Извлечение параметров из query при загрузке компонента
    useEffect(() => {

            viewModel.fetchLibraryInfo().then(() => setLibraryInfo(viewModel.getInfo()));

        setIsLoading(false); // Останавливаем загрузку
    }, [location.search]);

    // Обновление библиотечных данных при изменении категории
    useEffect(() => {


        const handleViewModelUpdate = () => {
            const info = viewModel.getInfo();
            setLibraryInfo(info);
        };

        viewModel.subscribe(handleViewModelUpdate);

        return () => {
            viewModel.unsubscribe(handleViewModelUpdate);
        };
    }, [activeCategory]);

    return (
        <>
            <CommonNavigation />
            <div className="library">
                <div className="library_container">
                    <CommonHeader name="Новости" />
                    <div className="library_container_content">
                        {isLoading ? (
                            <p className="loading-message">{loadingText}</p>
                        ) : (
                            <LazyList items={libraryInfo} ListItemComponent={ListItem} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotifyMain;
