import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import "../../../css/services/service-main.css";
import CommonNavigation from "../../common/CommonNavigation";
import { Item } from "../../../interface/library";
import LibraryViewModel from "../../../viewmodel/LibraryViewModel";
import { Notification } from "../../../interface/notification";
import ServiceIcon from "./ServiceMainListItem";
// @ts-ignore
import serviceImage from "../../../img/servises_option_activity.webp";
// @ts-ignore
import serviceImageHotel from "../../../img/servises_option_hotel.png";
// @ts-ignore
import serviceImageUser from "../../../img/servises_option_user.webp";
// @ts-ignore
import serviceImageNotify from "../../../img/servises_option_notification.png";


import CommonHeader from "../../common/CommonHeader";
import AccountViewModel from "../../../viewmodel/AccountViewModel";

function ServiceMain() {
    const [hasActivityAccess, setHasActivityAccess] = useState<boolean>(false);
    const viewModel = AccountViewModel.getInstance();
    const navigate = useNavigate();

    // Check for authentication on mount
    useEffect(() => {
        const fetchCookie = () => {
            if (!viewModel.handleCheckAuth()) {
                navigate(`/`);
            }
        };

        fetchCookie();
    }, [navigate]);

    // Check for activity access
    useEffect(() => {
        const checkAccess = async () => {
            const hasCreateActivityAccess = await viewModel.hasAccess("create_library");
            const hasEditActivityAccess = await viewModel.hasAccess("edit_library");
            setHasActivityAccess(hasCreateActivityAccess || hasEditActivityAccess);
        };

        checkAccess();
    }, []);

    const handleIconClick = (route: string) => {
        navigate(route);
    };

    return (
        <>
            <CommonNavigation />
            <div className="library">
                <div className="service-container">
                    <CommonHeader name="Сервисы" />
                    <div className="service-container-content">
                        {hasActivityAccess && (
                            <>
                            <ServiceIcon
                                imageUrl={serviceImage}
                                text="Активности"
                                onClick={() => handleIconClick('/service?type=activity')}
                            />
                            <ServiceIcon
                                imageUrl={serviceImageNotify}
                                text="Уведомления"
                                onClick={() => handleIconClick('/service?type=notify')}
                            />

                            </>
                        )}
                        {/* Add other service icons here */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceMain;
